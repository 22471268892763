<!--峰谷能耗-->
<template>
    <div id="analyseKPI" v-loading="loading" :element-loading-text="$t('general.a1')">
      <!-- 页面头部标题 -->
        <div class="header">
            <my-PageTitle>{{ $t("peakValleyAnalyse.title") }}</my-PageTitle>
        </div>
        <!-- 页面内容区域 -->
        <div class="main">
            <!-- 侧边电表树形及搜索栏 -->
            <div class="search">
                <measurePointSearch :isPower="show==2" @onSelect="selectedKeys"></measurePointSearch>
            </div>
            <!-- 图表区域 -->
            <div class="chart">
                <!-- 图表区域头部条件选择栏 -->
                <div class="chart-header">
                    <div class="chart-header-left">
                        <!-- 类型条件选择 -->
                        <a-select default-value="1" v-model="show" style="width: 120px;margin-right:15px" >
                            <a-select-option value="1">
                                {{ $t("peakValleyAnalyse.electricity") }}
                            </a-select-option>
                            <a-select-option value="2">
                                {{ $t("peakValleyAnalyse.power") }}
                            </a-select-option>
                            <a-select-option value="3">
                                {{ $t("peakValleyAnalyse.price") }}
                            </a-select-option>
                        </a-select>
                        <!-- 年、月、日条件选择 -->
                        <a-radio-group v-model="dateSelect" style="margin-right:15px">
                            <a-radio-button value="year">
                                {{ $t("peakValleyAnalyse.year") }}
                            </a-radio-button>
                            <a-radio-button value="month">
                                {{ $t("peakValleyAnalyse.month") }}
                            </a-radio-button>
                            <a-radio-button value="day">
                                {{ $t("peakValleyAnalyse.day") }}
                            </a-radio-button>
                        </a-radio-group>
                        <div class="date">
                            <!-- 年条件数据值选择 -->
                            <transition name="slide-fade">
                                <div class="date-select" v-if="dateSelect == 'year'">
                                    <a-select v-model="yearSelect" style="width: 80px">
                                        <a-select-option v-for="item in yearOptions" :key="item.key" :value="item.value">
                                            {{ item.value }}
                                        </a-select-option>
                                    </a-select>
                                    <a-checkbox v-model="customDate" style="margin-left:10px;margin-right:7px">
                                        {{ $t("peakValleyAnalyse.startMonth") }}
                                    </a-checkbox>
                                    <a-select :default-value="1" v-model="monthSelect" style="width: 120px" :disabled="!customDate">
                                        <a-select-option v-for="item in 12" :value="item" :key="item">
                                            {{`${item}`}}{{ $t("peakValleyAnalyse.month") }}
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </transition>
                            <!-- 月条件数据值选择 -->
                            <transition name="slide-fade">
                                <div class="date-select" v-if="dateSelect == 'month'">
                                    <a-month-picker v-model="defaultMonth" @change="onMonthDateChange" placeholder="Select month"/>
                                </div>
                            </transition>
                            <!-- 日条件数据值选择 -->
                            <transition name="slide-fade">
                                <div class="date-select" v-if="dateSelect == 'day'">
                                    <a-date-picker v-model="defaultDay" @change="onDateChange" />
                                </div>
                            </transition>
                        </div>
                    </div>
                    <div>
                        <!-- 查询按钮及设置按钮 -->
                        <a-button type="primary" ghost @click="query">{{ $t("peakValleyAnalyse.inquire") }}</a-button>
                    </div>
                </div>
                <!-- 图表 -->
                <div class="chart-main">
                    <!-- 无图表状态 -->
                    <!-- <a-empty v-if="show == '1'">
                        <span class="explain">
                            请选择对应计量点进行查询
                        </span>
                    </a-empty> -->
                    <!-- 电费图表 -->
                    <div class="echarts"  v-if="show == '3'">
                        <peakValleyEcharts1 :dateSelect="dateSelect" :chartData="chartData"/>
                    </div>
                    <!-- 功率图表 -->
                    <div class="echarts"  v-if="show == '2'">
                        <peakValleyEcharts2 :dateSelect="dateSelect" :chartData="chartData"/>
                    </div>
                    <!-- 用电量 -->
                    <div class="echarts"  v-if="show == '1'">
                        <peakValleyEcharts3 :dateSelect="dateSelect" :chartData="chartData"/>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import PageTitle from '../../components/Title/PageTitle.vue'
import peakValleyEcharts1 from './components/peakValleyEcharts1.vue'
import peakValleyEcharts2 from './components/peakValleyEcharts2.vue'
import peakValleyEcharts3 from './components/peakValleyEcharts3.vue'
import measurePointSearch from './components/measurePoint/measurePointSearch.vue'
import {getHourlyDataOfDay,getDailyDataOfMonth,getMonthlyDataOfYear,getHourlyDataOfDayPower,getDailyDataOfMonthPower,getMonthDataOfYearPower } from "../../api/energy";
import moment from 'moment';


export default {
    components:{
        'my-PageTitle':PageTitle,
        'peakValleyEcharts1':peakValleyEcharts1,
        'peakValleyEcharts2':peakValleyEcharts2,
        'peakValleyEcharts3':peakValleyEcharts3,
        'measurePointSearch':measurePointSearch,
    },
    data(){
        return{
            defaultMonth:moment(),
            defaultDay:moment(),
            loading:false,
            expandedKeys: [],
            searchValue: '',
            autoExpandParent: true,
            replaceFields: {children: 'children',title: 'name',key:'id'},
            gData:[],            
            treeNodeData:{},
            dataList:[],
            dateSelect:'year',
            actualTargetSelect:'actual',
            customDate:false,
            show:'1',
            practicalData:{},
            targetData:{},
            mode2: 'time',
            nullState:true,
            //2022-7-19 li_yj
            chartData:[],
            measurePointIds:[],
            date:"",
            yearSelect:moment().format("YYYY"),
            monthSelect:1,
        }
    },
    computed:{
        yearOptions(){
            let nowDate=new Date();
            let yearNow=nowDate.getFullYear();
            let option=[];
            let item={};
            let k=0;
            for(let i=yearNow;i>2010;i--){
                item={key:k,value:i};
                option.push(item);
            }
            return option;
        },
    },
    methods:{
        selectedKeys(e){
            console.log("selectedKeys",e);
            this.measurePointIds=e;
        },
        checkedKeys(e){
            console.log("checkedKeys",e);
            this.measurePointIds=e;
        },
        
        // 获取搜索列表
        generateList(data){
            for (let i = 0; i < data.length; i++) {
                const node = data[i];
                const id = node.id;
                this.dataList.push({ id, title: node.title });
                if (node.children) {
                    this.generateList(node.children);
                }
            }
        },
        //时间变化
        onDateChange(e){
            this.date=e.format("YYYY/MM/DD");
        },
        onMonthDateChange(e){
            this.date=e.format("YYYY/MM");
        },
        // 搜索函数
        onChange(e) {
            console.log("onChange e:",e);
            const value = e.target.value;
            const expandedKeys = this.dataList
            .map(item => {
                if (item.title.indexOf(value) > -1) {
                    return this.getParentKey(item.id, this.data);
                }
                return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i);
            Object.assign(this, {
                expandedKeys,
                searchValue: value,
                autoExpandParent: true,
            });
        },
        getParentKey(id, tree){
            let parentKey;
            for (let i = 0; i < tree.length; i++) {
                const node = tree[i]
                if (node.children) {
                    if (node.children.some(item => item.id === id)) {
                        parentKey = node.id;
                    } else if (this.getParentKey(id, node.children)) {
                        parentKey = this.getParentKey(id, node.children);
                    }
                }
            }
            console.log('parentKey',parentKey)
            return parentKey;
        },
        onExpand(expandedKeys) {
            this.expandedKeys = expandedKeys;
            this.autoExpandParent = false;
        },        
        getHourlyDataOfDayPower(){
            let params = {
                action:1,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                measurePointIds: this.measurePointIds,
                date:this.date,
                serviceType:parseInt(this.show),
            };
            console.log("peakValleyAnalyse getHourlyDataOfDayPower params:",params);
            this.loading = true;
            getHourlyDataOfDayPower(params)
                .then((res) => {
                    console.log("getHourlyDataOfDayPower result:",res);
                    this.chartData=res.data;
                    this.loading = false;
                }).catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        getHourlyDataOfDay(){
            let params = {
                action:1,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                measurePointIds: this.measurePointIds,
                date:this.date,
                serviceType:parseInt(this.show),
            };
            console.log("peakValleyAnalyse getHourlyDataOfDay params:",params);
            this.loading = true;
            getHourlyDataOfDay(params)
                .then((res) => {
                    console.log("getHourlyDataOfDay result:",res);
                    this.chartData=res.data;
                    this.loading = false;
                }).catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        getDailyDataOfMonthPower(){
            let params = {
                action:1,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                measurePointIds: this.measurePointIds,
                date:this.date,
                serviceType:parseInt(this.show),
            };
            console.log("peakValleyAnalyse getDailyDataOfMonthPower params:",params);
            this.loading = true;
            getDailyDataOfMonthPower(params)
                .then((res) => {
                    console.log("getDailyDataOfMonthPower result:",res);
                    this.chartData=res.data;
                    this.loading = false;
                }).catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        getDailyDataOfMonth(){
            let params = {
                action:1,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                measurePointIds: this.measurePointIds,
                date:this.date,
                serviceType:parseInt(this.show),
            };
            console.log("peakValleyAnalyse getDailyDataOfMonth params:",params);
            this.loading = true;
            getDailyDataOfMonth(params)
                .then((res) => {
                    console.log("getDailyDataOfMonth result:",res);
                    this.chartData=res.data;
                    this.loading = false;
                }).catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        getMonthlyDataOfYear(){
            if(this.customDate){
                this.date=this.yearSelect+"/"+this.monthSelect;
            }else{
                this.date=this.yearSelect+"/1";
            }

            let params = {
                action:1,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                measurePointIds: this.measurePointIds,
                date:this.date,
                serviceType:parseInt(this.show),
            };
            console.log("peakValleyAnalyse getMonthlyDataOfYear params:",params);
            this.loading = true;
            getMonthlyDataOfYear(params)
                .then((res) => {
                    console.log("getMonthlyDataOfYear result:",res);
                    this.chartData=res.data;
                    this.loading = false;
                }).catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        getMonthDataOfYearPower(){
            if(this.customDate){
                this.date=this.yearSelect+"/"+this.monthSelect;
            }else{
                this.date=this.yearSelect+"/1";
            }

            let params = {
                action:1,
                clientId: this.$store.getters.clientId,
                siteId: this.$route.query.id,
                sitegrId: this.$store.getters.sitegrId,
                tenantId: 0,
                measurePointIds: this.measurePointIds,
                date:this.date,
                serviceType:parseInt(this.show),
            };
            console.log("peakValleyAnalyse getMonthDataOfYearPower params:",params);
            this.loading = true;
            getMonthDataOfYearPower(params)
                .then((res) => {
                    console.log("getMonthDataOfYearPower result:",res);
                    this.chartData=res.data;
                    this.loading = false;
                }).catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        // 
        query(){
            //加载日峰谷能耗数据
            switch(this.show){
                case "1"://用电量
                case "3"://电费           
                    switch(this.dateSelect){
                        case "day":
                            this.date=this.defaultDay.format("YYYY/MM/DD");
                            this.getHourlyDataOfDay();
                            break;
                        case "month":
                            this.date=this.defaultMonth.format("YYYY/MM");
                            this.getDailyDataOfMonth();
                            break;
                        case "year":
                            this.getMonthlyDataOfYear();
                            break;
                    }
                    break;
                case "2"://功率
                    switch(this.dateSelect){
                        case "day":
                            this.date=this.defaultDay.format("YYYY/MM/DD");
                            this.getHourlyDataOfDayPower();
                            break;
                        case "month":
                            this.date=this.defaultMonth.format("YYYY/MM");
                            this.getDailyDataOfMonthPower();
                            break;
                        case "year":
                            this.getMonthDataOfYearPower();
                            break;
                    }
                    break;  
            }          
            // const { ammeterData } = this.treeNodeData
            // console.log(ammeterData)
            // if(ammeterData == undefined){
            //     this.$message.error('未选择电表,请选择电表进行查询',20);
            // }else if(!ammeterData){
            //     this.$message.warning('该电表无数据,请选择其它电表进行查询',20);
            // }else{
            //     const dateSelect = this.dateSelect
            //     const actualTargetSelect = this.actualTargetSelect
            //     if(actualTargetSelect == 'actual'){
            //         if(dateSelect == 'day'){
            //             this.show = '4'
            //         }else{
            //             this.show = '2'
            //         }
            //     }else{
            //         if(dateSelect == 'day'){
            //             this.show = '4'
                        
            //         }else{
            //             this.show = '3'
            //         }
            //     }
            //     if(dateSelect=='year' && actualTargetSelect=='actual')
            //     this.drawLineBar()
            // } 
        },          
    }
}
</script>

<style scoped>
#analyseKPI{
    width: 100%;
    height: 100%;
}
.main{
    display: flex;
    width: 100%;
    height: calc(100% - 50px);
    padding: 20px 20px 10px 20px;
}
.search{
    width: 300px;
    margin-right: 15px;
}
.tree{
    height: calc(100% - 200px);
    border-right: 1px solid rgba(12, 12, 12, 0.25);
    overflow: auto;
}
div /deep/.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background-color: #7682ce;
}
.chart{
    width: calc(100% - 320px);
}
.chart-header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
}
.chart-header-left{
    display: flex;
}
.date{
    position: relative;
}
.date-select{
    width: 400px;
    position: absolute;
}
.chart-main{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 48px);
}
.explain{
    font-size: 20px;
    color: #7682ce;
}
.echarts{
    width: 100%;
    height: 100%;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>