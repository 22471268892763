<!-- 峰谷能耗--图表1--用电量/电费 + 年/月图表-->
<template>
  <div id="peakValleyEcharts3">
      <div class="header">
          <div id="echartsIdABC" :style="{ width: '100%', height: '100%' }"></div>
      </div>
      <div class="floor">
          <div id="echartsIdABCd" :style="{ width: '100%', height: '100%' }"></div>
      </div>
  </div>
</template>

<script>
import { EleResize } from '../../../utils/esresize'

export default {
    props:{
        chartData:Array,
        dateSelect:String,
    },
    data(){
        return {            
            pieOption:{
                color:['#e65a56','#ff974c','#5aaef3','#62d9ad'],
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    bottom: "0%",
                    left: "center",
                    formatter: (name)=>{
                        // const { data } = this.data
                        // let total = 0
                        // let target
                        // let units = this.data.units
                        // for(let i = 0;i < data.length;i++){
                        //     total +=data[i].value
                        //     if(data[i].name==name){
                        //         target = data[i].value
                        //     }
                        // }
                        return name //+ ' ' + target + units + ' ' + ((target/total)*100).toFixed(2) + '%'
                    }
                    },
                series: [
                    {
                        type: 'pie',
                        radius: ['50%', '70%'],
                        itemStyle: {
                            borderRadius: 5,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        data: [
                            { value: 300, name: this.$t("peakValleyAnalyse.peak") },
                            { value: 735, name: this.$t("peakValleyAnalyse.high") },
                            { value: 1048, name: this.$t("peakValleyAnalyse.flat") },
                            { value: 484, name: this.$t("peakValleyAnalyse.trough") },
                        ]
                    }
                ]
            },
            gridOption:{
                tooltip: {
                    trigger: 'item'
                },
                legend: {},
                grid: {
                    left: '5%',
                    right: '4%',
                    bottom: '3%',
                    top:'12%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    axisLabel: { interval: 0, rotate: 30 },
                    data: ['0:00','1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00','9:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','18:00','19:00','20:00','21:00','22:00','23:00']
                },
                yAxis: [
                    {
                        type: 'value',
                        position: 'left',
                        name:this.$t("peakValleyAnalyse.accumulateElectricity"),
                        axisLine: {
                            show: true,
                        },
                    }
                ],
                series: //this.chartData,
                [
                //    {
                //       name:'高峰',
                //        type: 'bar',
                //        itemStyle: {color: "#e65a56"},
                //        data: [245]
                //    }, 
                //    {
                //        name:'高峰',
                //        type: 'bar',
                //        itemStyle: {color: "#e65a56"},
                //        data: [295]
                //     },
                ]
            },                        

        }
    },
    mounted(){
        this.drawLineBar()
        this.drawLineBar1()        
    },
    watch:{
        chartData: {
            handler(val) {
                console.log(val)
                let dataX=[];                
                let len=0;
                if(val!=null&&val.length>0){
                    len=val[0].data.length;                    
                }
                switch(this.dateSelect){
                    case "day":
                        for(let i=0;i<len;i++){
                            dataX.push(i+":00");
                        }
                        break;
                    case "month":
                        for(let i=1;i<len+1;i++){
                            dataX.push(i+this.$t("peakValleyAnalyse.day"));
                        }
                        break;
                    case "year":
                        for(let i=1;i<len+1;i++){
                            dataX.push(i+this.$t("peakValleyAnalyse.month"));
                        }
                        break;
                }
                this.gridOption={
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {},
                    grid: {
                        left: '5%',
                        right: '4%',
                        bottom: '3%',
                        top:'12%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        axisLabel: { interval: 0, rotate: 30 },
                        data: dataX,
                    },
                    yAxis: [
                        {
                            type: 'value',
                            position: 'left',
                            name:this.$t("peakValleyAnalyse.accumulateElectricity"),
                            axisLine: {
                                show: true,
                            },
                        },
                        {
                            type: 'value',
                            name: this.$t("peakValleyAnalyse.proportion"),
                            position: 'right',
                            // alignTicks: true,
                            axisLine: {
                                show: true,
                                // lineStyle: {
                                // color: colors[2]
                                // }
                            },
                            // axisLabel: {
                            //     formatter: '{value} °C'
                            // }
                        }
                    ],
                    series: val,
                };                

                //环形
                let color=[];
                let data=[];
                if(val!=null&&val.length>0){
                    for(let i=0;i<val.length;i++){
                        if(val[i].type=="bar"){
                            color.push(val[i].itemStyle.color);
                            data.push({value:val[i].value,name:val[i].name});
                        }
                    }
                };
                this.pieOption={
                    color:color,
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        bottom: "0%",
                        left: "center",
                        formatter: (name)=>{
                            // const { data } = this.data
                            // let total = 0
                            // let target
                            // let units = this.data.units
                            // for(let i = 0;i < data.length;i++){
                            //     total +=data[i].value
                            //     if(data[i].name==name){
                            //         target = data[i].value
                            //     }
                            // }
                            return name //+ ' ' + target + units + ' ' + ((target/total)*100).toFixed(2) + '%'
                        }
                    },
                    series: [
                        {
                            type: 'pie',
                            radius: ['50%', '70%'],
                            itemStyle: {
                                borderRadius: 5,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            label: {
                                show: false,
                                position: 'center'
                            },
                            data: data,
                        }
                    ]
                };

                this.drawLineBar();
                this.drawLineBar1();                
            },
            deep: true, // 深度监听
            // immediate: true, // 初次监听即执行  
        },
    },
    methods:{
        // 折柱混合图
        drawLineBar() {
            let myChart = this.$echarts.init(document.getElementById('echartsIdABC'));
            let resizeDiv = document.getElementById('echartsIdABC');
            myChart.setOption(this.pieOption);
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        },
        drawLineBar1(){
            let myChart = this.$echarts.init(document.getElementById('echartsIdABCd'));
            let resizeDiv = document.getElementById('echartsIdABCd');
            myChart.setOption(this.gridOption,true);
            console.log("drawLineBar1",this.gridOption);
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        }
    }
}
</script>

<style scoped>
#peakValleyEcharts3{
    display: flex;
    width: 100%;
    height: 100%;
}
.header{
    width: 25%;
    height: 100%;
}
.floor{
    width: 75%;
    height: 100%;    
}
</style>