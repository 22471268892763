<!--能源模块--计量点搜索-->
<template>
  <div class="point-tree-box">                  
    <div class="display-between">  
      <div class="display-column">
        <span>Gr1</span>
        <a-select class="select" v-model="data.gr1" @change="handleGrChange(1)">
          <a-select-option
              v-for="(itme, index) in data.gr1s"
              :key="index"
              :value="itme.id"
            >
              {{ itme.name }}
            </a-select-option>
        </a-select>
      </div>
      <div class="display-column">
        <span>Gr2</span>
        <a-select class="select" v-model="data.gr2" @change="handleGrChange(2)">
          <a-select-option
              v-for="(itme, index) in data.gr2s"
              :key="index"
              :value="itme.id"
            >
              {{ itme.name }}
            </a-select-option>
        </a-select>
      </div>
      <div class="display-column">
        <span>Gr3</span>
        <a-select class="select" v-model="data.gr3" @change="handleGrChange(3)">
          <a-select-option
              v-for="(itme, index) in data.gr3s"
              :key="index"
              :value="itme.id"
            >
              {{ itme.name }}
            </a-select-option>
        </a-select>
      </div>
    </div>
    <a-input-search style="margin-bottom: 8px" placeholder="查询" @change="queryPoints" />
    <!-- <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
      {{ $t("energy.enedata025") }}
    </a-checkbox> -->
    <a-tree
      style=""
      class="point-tree"      
      show-line
      v-model="checkedPoints"
      :tree-data="treePointLists"
      :selectable="true"
      :multiple="false"
      :replaceFields="{children:'children', title:'text', key:'no' }"      
      @select="pointSelect"
    >
      <a-icon slot="switcherIcon" type="down" />
    </a-tree>
  </div>
</template>

<script>
import { getEnergyPointGrPathList,getEnergyStatisticsData,getMeasurementPointList } from "../../../../api/energy";
export default {
  name: "energy",
  props: {
    isPower:Boolean,
  },
  data() {
    return {
      loading: false,
      changeState: false,
      indeterminate:false,
      checkAll:false,
      checkedPoints:[],
      pointListsGr:[],
      analogPointList:[],//模拟信号点（功率）
      pointLists:[],//其他信号点（累计点、运算点和虚拟点）
      treePointLists:[],
      treePointNoLists:[],
      data:{
                measurementList:[],
                measurement:'',
                dateList:[],
                summer:'',
                winter:'',
                choicePointList:[],
                gr1:'',
                gr1s:[],
                gr2:'',
                gr2s:[],
                gr3:'',
                gr3s:[],
                pointList:[],
                unit:'',
            },
    };
  },
  mounted() {
    this.getEnergyPointGrPathList();
    this.getEnergyInitData();
    this.getEnergyInitAnalogData();
  },
  watch:{
    isPower(val){
      if(val){//早否功率
        this.setTreePointLists(this.analogPointList);
      }
      else{
        this.setTreePointLists(this.pointLists);
      }
    }
  },
  methods: {
    getEnergyInitAnalogData(){
      let params = {
        action:0,
        clientId: this.$store.getters.clientId,
        sitegrId: this.$store.getters.sitegrId,
        siteId: this.$route.query.id,
        tenantId: 0,
      };
      console.log("getEnergyInitAnalogData params:",params);
      // this.loading = true;    
      getMeasurementPointList(params)
      .then((res) => {
        console.log("getEnergyInitAnalogData res.data:",res.data);
        // this.loading = false;
        this.analogPointList = res.data;     
      }).catch((err) => {
        // this.loading = false;
        console.log(err);
      });
    },
    getEnergyInitData(){
      let params = {
        action:0,
        clientId: this.$store.getters.clientId,
        sitegrId: this.$store.getters.sitegrId,
        siteId: this.$route.query.id,
        tenantId: 0,
      };
      console.log("getEnergyInitData params:",params);
      this.loading = true;
    
      getEnergyStatisticsData(params)
      .then((res) => {
        console.log("getEnergyInitData res.data:",res.data);
        this.loading = false;
        this.pointLists = res.data.measurementPointList;
        this.setTreePointLists(this.pointLists);
        // this.treePointLists = [...this.pointLists];
        // for(let i=0;i<this.treePointLists.length;i++){
        //   this.treePointNoLists.push(this.treePointLists[i].no);
        // }       
      }).catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },

    //设置树列表数据
    setTreePointLists(pointLists){
      this.treePointLists = [...pointLists];
      for(let i=0;i<this.treePointLists.length;i++){
        this.treePointNoLists.push(this.treePointLists[i].no);
      }       
    },

    //取GR1、GR2、GR3
    getEnergyPointGrPathList(){
      let par={
        siteId:this.$route.query.id,
      };
      console.log("getEnergyPointGrPathList par:",par);      
      getEnergyPointGrPathList(par)
      .then((res) => {
        console.log("getEnergyPointGrPathList res:",res);  
        let list =res.data;
        if(list!=null && list.length>0){
          this.data.gr1s.length=0;
          let first = {id:0,name:""};
          this.data.gr1s.push(first); 
          this.pointListsGr.length=0;
          for(let i=0;i<list.length;i++){
            if(list[i].groupType==1){
              this.pointListsGr.push(list[i]);
              if(list[i].hierarchyDepth==2){//二阶层
                this.data.gr1s.push(list[i]);
              }
            }
          }          
        }
      }).catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },

    onCheckAllChange(e){
      Object.assign(this, {
        checkedPoints: e.target.checked ? this.treePointNoLists : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
      // this.clearPointDate();
    },
    queryPoints(e){
        const value = e.target.value;
        console.log("queryPoints",value);
        if(value===''){
            this.treePointLists = [...this.pointLists];
            this.treePointNoLists.length=0;
            for(let i=0;i<this.treePointLists.length;i++){
              this.treePointNoLists.push(this.treePointLists[i].no);
            }
        }else{
            const points = [...this.pointLists];
            this.treePointLists = points.filter(function(item){
                console.log("queryPoints txt",item.text);
                if(new String(item.text).indexOf(value)>=0){
                  return true;
                }
            });
          this.treePointNoLists.length=0;
          for(let i=0;i<this.treePointLists.length;i++){
            this.treePointNoLists.push(this.treePointLists[i].no);
          }
             console.log("queryPoints treePointLists",this.treePointLists);
        }
    },
    handleGrChange(gr){
        let id=0;
        if(gr==1){        
            id=this.data.gr1;
            this.data.gr2s.length=0;
            this.data.gr3s.length=0;
            let first = {id:0,name:""};
            this.data.gr2s.push(first); 
            this.data.gr2 = 0;
            this.data.gr3 = "";
            for(let i=0;i<this.pointListsGr.length;i++){
            if(this.pointListsGr[i].hierarchyDepth==3&&this.pointListsGr[i].parentId==this.data.gr1){
                this.data.gr2s.push(this.pointListsGr[i]);
            }
            }
            console.log("data.gr2s:",this.data.gr2s);
        }else if(gr==2){
            id= this.data.gr2;       
            this.data.gr3s.length=0;
            let first = {id:0,name:""};
            this.data.gr3s.push(first); 
            this.data.gr3 = 0;
            for(let i=0;i<this.pointListsGr.length;i++){
              if(this.pointListsGr[i].hierarchyDepth==4&&this.pointListsGr[i].parentId==this.data.gr2){
                  this.data.gr3s.push(this.pointListsGr[i]);
              }
            }
        }
        else
            id=this.data.gr3;
        this.filterData(id);
    },
    filterData(id){
      if(id>0){
          const idStr=id+"";
          console.log("filterData id",idStr);
          const points = [...this.pointLists];
          
          this.treePointLists = points.filter(function(item){
              if(new String(item.pointInfo).indexOf(idStr)>=0){
                
                return true;
              }
          });
          this.treePointNoLists.length=0;
          for(let i=0;i<this.treePointLists.length;i++){
            this.treePointNoLists.push(this.treePointLists[i].no);
          }
          console.log("queryPoints treePointLists",this.treePointLists);
      }
    },
    pointSelect(selectedKeys, info){
      this.$emit("onSelect",selectedKeys);
      // console.log('selected', selectedKeys, info);
      // console.log(JSON.stringify(info))
      // if(info.selected){
      //   let key = info.node.$vnode.data.key;
      //   this.checkedPoints.push(key);
      // }else{
      //   //this.checkedPoints.reduce
      //   let key = info.node.$vnode.data.key;
      //   let arr = new Array();
      //   this.checkedPoints.forEach(element => {
      //     if(element!=key){
      //       arr.push(element);
      //     }
      //   });

      //   this.checkedPoints = arr;
      // }
      // this.clearPointDate();
    },
    pointCheck(checkedKeys, info){
      console.log('onCheck', checkedKeys, info);
      this.$emit("onCheck",checkedKeys);
      // this.clearPointDate();
    },
  },
};
</script>

<style scoped>
.title {
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  font-size: 13px;
  color: #b5b5b5;
  font-family: "Microsoft YaHei";
}
.title-name{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.point-tree-box{width: 100%;height: 100%;padding:10px;border: 1px solid #d9d9d9 ;}
.point-tree{width: 100%;height:calc(100% - 110px);overflow: auto;}
.display-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 8px
}
.display-between-2 {
  display: flex;
  justify-content: flex-start;
}
.display-column {
  width: 30%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.display-column span {
  font-size: 14px;
  margin-bottom: 5px;
}
.inquire {
  padding:0 10px;
  margin-bottom: 10px;
}
.inquire-item {
  width: 100%;
}
</style>