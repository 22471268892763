<!-- 峰谷能耗--图表1--用电量/电费 + 年/月图表-->
<template>
  <div id="peakValleyEcharts2">
    <div id="echartsIdABC" :style="{ width: '100%', height: '100%' }"></div>
  </div>
</template>

<script>
import { EleResize } from '../../../utils/esresize'

export default {
    props:{
        chartData:Array,
        dateSelect:String,
    },
    data(){
        return {
            gridOption:{
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow' 
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: ['1'+this.$t("peakValleyAnalyse.month"), '2'+this.$t("peakValleyAnalyse.month"), '3'+this.$t("peakValleyAnalyse.month"), '4'+this.$t("peakValleyAnalyse.month"),
                     '5'+this.$t("peakValleyAnalyse.month"), '6'+this.$t("peakValleyAnalyse.month"), '7'+this.$t("peakValleyAnalyse.month"), '8'+this.$t("peakValleyAnalyse.month"),
                     '9'+this.$t("peakValleyAnalyse.month"),'10'+this.$t("peakValleyAnalyse.month"),'11'+this.$t("peakValleyAnalyse.month"),'12'+this.$t("peakValleyAnalyse.month")]
                },
                yAxis: [
                    {
                        type: 'value',
                        position: 'left',
                        name:this.$t("peakValleyAnalyse.averagePower"),
                        axisLine: {
                            show: true,
                        },
                    },
                    {
                        type: 'value',
                        position: 'right',
                        name:this.$t("peakValleyAnalyse.maximumPower"),
                        axisLine: {
                            show: true,
                        },
                    },
                ],
                series: [                   
                ]
            }
        }
    },
    watch:{
        chartData: {
            handler(val) {
                let dataX=[];                
                let len=0;
                if(val!=null&&val.length>0){
                    len=val[0].data.length;                    
                }
                switch(this.dateSelect){
                    case "day":
                        for(let i=0;i<len;i++){
                            dataX.push(i+":00");
                        }
                        break;
                    case "month":
                        for(let i=1;i<len+1;i++){
                            dataX.push(i+this.$t("peakValleyAnalyse.day"));
                        }
                        break;
                    case "year":
                        for(let i=1;i<len+1;i++){
                            dataX.push(i+this.$t("peakValleyAnalyse.month"));
                        }
                        break;
                }
                this.gridOption={
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {},
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        axisLabel: { interval: 0, rotate: 30 },
                        data: dataX,
                    },
                    yAxis: [
                        {
                            type: 'value',
                            position: 'left',
                            name:this.$t("peakValleyAnalyse.averagePower"),
                            axisLine: {
                                show: true,
                            },
                        },
                        {
                            type: 'value',
                            name: this.$t("peakValleyAnalyse.maximumPower"),
                            position: 'right',
                            // alignTicks: true,
                            axisLine: {
                                show: true,
                                // lineStyle: {
                                // color: colors[2]
                                // }
                            },
                            // axisLabel: {
                            //     formatter: '{value} °C'
                            // }
                        }
                    ],
                    series: val,
                }; 

                
                this.drawLineBar1();                
            },
            deep: true, // 深度监听
            // immediate: true, // 初次监听即执行  
        },
    },
    mounted(){
        this.drawLineBar1()
    },
    methods:{
        drawLineBar1(){
            let myChart = this.$echarts.init(document.getElementById('echartsIdABC'));
            let resizeDiv = document.getElementById('echartsIdABC');
            myChart.setOption(this.gridOption);
            let listener = () => {
                myChart.resize();
            };
            EleResize.on(resizeDiv, listener);
        }
    }
}
</script>

<style scoped>
#peakValleyEcharts2{
    display: flex;
    width: 100%;
    height: 100%;
}
</style>